const countryCodes = [
    {
        name: {
            dk: "Danmark",
            se: "Danmark",
            no: "Danmark",
            en: "Denmark",
        },
        phoneCode: "+45",
        emoji: "🇩🇰",
    },
    {
        name: {
            dk: "Sverige",
            se: "Sverige",
            no: "Sverige",
            en: "Sweden",
        },
        phoneCode: "+46",
        emoji: "🇸🇪",
    },
    {
        name: {
            dk: "Norge",
            se: "Norge",
            no: "Norge",
            en: "Norway",
        },
        phoneCode: "+47",
        emoji: "🇳🇴",
    },
    {
        name: {
            dk: "Østrig",
            se: "Österrike",
            no: "Østerrike",
            en: "Austria",
        },
        phoneCode: "+43",
        emoji: "🇦🇹",
    },
    {
        name: {
            dk: "Belgien",
            se: "Belgien",
            no: "Belgia",
            en: "Belgium",
        },
        phoneCode: "+32",
        emoji: "🇧🇪",
    },
    {
        name: {
            dk: "Bulgarien",
            se: "Bulgarien",
            no: "Bulgaria",
            en: "Bulgaria",
        },
        phoneCode: "+359",
        emoji: "🇧🇬",
    },
    {
        name: {
            dk: "Kroatien",
            se: "Kroatien",
            no: "Kroatia",
            en: "Croatia",
        },
        phoneCode: "+385",
        emoji: "🇭🇷",
    },
    {
        name: {
            dk: "Cypern",
            se: "Cypern",
            no: "Kypros",
            en: "Cyprus",
        },
        phoneCode: "+357",
        emoji: "🇨🇾",
    },
    {
        name: {
            dk: "Tjekkiet",
            se: "Tjeckien",
            no: "Tsjekkia",
            en: "Czech Republic",
        },
        phoneCode: "+420",
        emoji: "🇨🇿",
    },
    {
        name: {
            dk: "Estland",
            se: "Estland",
            no: "Estland",
            en: "Estonia",
        },
        phoneCode: "+372",
        emoji: "🇪🇪",
    },
    {
        name: {
            dk: "Finland",
            se: "Finland",
            no: "Finland",
            en: "Finland",
        },
        phoneCode: "+358",
        emoji: "🇫🇮",
    },
    {
        name: {
            dk: "Frankrig",
            se: "Frankrike",
            no: "Frankrike",
            en: "France",
        },
        phoneCode: "+33",
        emoji: "🇫🇷",
    },
    {
        name: {
            dk: "Færøerne",
            se: "Färöarna",
            no: "Færøyene",
            en: "Faroe Islands",
        },
        phoneCode: "+298",
        emoji: "🇫🇴",
    },
    {
        name: {
            dk: "Tyskland",
            se: "Tyskland",
            no: "Tyskland",
            en: "Germany",
        },
        phoneCode: "+49",
        emoji: "🇩🇪",
    },
    {
        name: {
            dk: "Grækenland",
            se: "Grekland",
            no: "Hellas",
            en: "Greece",
        },
        phoneCode: "+30",
        emoji: "🇬🇷",
    },
    {
        name: {
            dk: "Grønland",
            se: "Grönland",
            no: "Grønland",
            en: "Greenland",
        },
        phoneCode: "+299",
        emoji: "🇬🇱",
    },
    {
        name: {
            dk: "Ungarn",
            se: "Ungern",
            no: "Ungarn",
            en: "Hungary",
        },
        phoneCode: "+36",
        emoji: "🇭🇺",
    },
    {
        name: {
            dk: "Island",
            se: "Island",
            no: "Island",
            en: "Iceland",
        },
        phoneCode: "+354",
        emoji: "🇮🇸",
    },
    {
        name: {
            dk: "Irland",
            se: "Irland",
            no: "Irland",
            en: "Ireland",
        },
        phoneCode: "+353",
        emoji: "🇮🇪",
    },
    {
        name: {
            dk: "Italien",
            se: "Italien",
            no: "Italia",
            en: "Italy",
        },
        phoneCode: "+39",
        emoji: "🇮🇹",
    },
    {
        name: {
            dk: "Letland",
            se: "Lettland",
            no: "Latvia",
            en: "Latvia",
        },
        phoneCode: "+371",
        emoji: "🇱🇻",
    },
    {
        name: {
            dk: "Liechtenstein",
            se: "Liechtenstein",
            no: "Liechtenstein",
            en: "Liechtenstein",
        },
        phoneCode: "+423",
        emoji: "🇱🇮",
    },
    {
        name: {
            dk: "Litauen",
            se: "Litauen",
            no: "Litauen",
            en: "Lithuania",
        },
        phoneCode: "+370",
        emoji: "🇱🇹",
    },
    {
        name: {
            dk: "Luxembourg",
            se: "Luxemburg",
            no: "Luxembourg",
            en: "Luxembourg",
        },
        phoneCode: "+352",
        emoji: "🇱🇺",
    },
    {
        name: {
            dk: "Malta",
            se: "Malta",
            no: "Malta",
            en: "Malta",
        },
        phoneCode: "+356",
        emoji: "🇲🇹",
    },
    {
        name: {
            dk: "Holland",
            se: "Nederländerna",
            no: "Nederland",
            en: "Netherlands",
        },
        phoneCode: "+31",
        emoji: "🇳🇱",
    },
    {
        name: {
            dk: "Polen",
            se: "Polen",
            no: "Polen",
            en: "Poland",
        },
        phoneCode: "+48",
        emoji: "🇵🇱",
    },
    {
        name: {
            dk: "Portugal",
            se: "Portugal",
            no: "Portugal",
            en: "Portugal",
        },
        phoneCode: "+351",
        emoji: "🇵🇹",
    },
    {
        name: {
            dk: "Rumænien",
            se: "Rumänien",
            no: "Romania",
            en: "Romania",
        },
        phoneCode: "+40",
        emoji: "🇷🇴",
    },
    // {
    //     name: {
    //         dk: "Rusland",
    //         se: "Ryssland",
    //         no: "Russland",
    //         en: "Russia",
    //     },
    //     phoneCode: "+7",
    //     emoji: "🇷🇺",
    // },
    {
        name: {
            dk: "San Marino",
            se: "San Marino",
            no: "San Marino",
            en: "San Marino",
        },
        phoneCode: "+378",
        emoji: "🇸🇲",
    },
    {
        name: {
            dk: "Serbien",
            se: "Serbien",
            no: "Serbia",
            en: "Serbia",
        },
        phoneCode: "+381",
        emoji: "🇷🇸",
    },
    {
        name: {
            dk: "Slovakiet",
            se: "Slovakien",
            no: "Slovakia",
            en: "Slovakia",
        },
        phoneCode: "+421",
        emoji: "🇸🇰",
    },
    {
        name: {
            dk: "Slovenien",
            se: "Slovenien",
            no: "Slovenia",
            en: "Slovenia",
        },
        phoneCode: "+386",
        emoji: "🇸🇮",
    },
    {
        name: {
            dk: "Spanien",
            se: "Spanien",
            no: "Spania",
            en: "Spain",
        },
        phoneCode: "+34",
        emoji: "🇪🇸",
    },
    {
        name: {
            dk: "Schweiz",
            se: "Schweiz",
            no: "Sveits",
            en: "Switzerland",
        },
        phoneCode: "+41",
        emoji: "🇨🇭",
    },
    {
        name: {
            dk: "Ukraine",
            se: "Ukraina",
            no: "Ukraina",
            en: "Ukraine",
        },
        phoneCode: "+380",
        emoji: "🇺🇦",
    },
    {
        name: {
            dk: "Storbritannien",
            se: "Storbritannien",
            no: "Storbritannia",
            en: "United Kingdom",
        },
        phoneCode: "+44",
        emoji: "🇬🇧",
    },
    {
        name: {
            dk: "USA",
            se: "USA",
            no: "USA",
            en: "United States",
        },
        phoneCode: "+1",
        emoji: "🇺🇸",
    },
    // {
    //     name: {
    //         dk: "Vatikanstaten",
    //         se: "Vatikanstaten",
    //         no: "Vatikanstaten",
    //         en: "Vatican City",
    //     },
    //     phoneCode: "+39",
    //     emoji: "🇻🇦",
    // },
]

export const popularCountryCodes = [
    "Denmark",
    "Sweden",
    "Norway",
    "Germany",
    "United Kingdom",
    "Spain",
    "Greenland",
    "France",
    "Italy",
    "Faroe Islands",
    "Ukraine",
    "Netherlands",
    "Poland",
    "Portugal",
]

export default countryCodes
