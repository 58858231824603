import { createContext, useContext } from "react"
import { GlobalSettings } from "../../sanity/types"

export const SettingsContext = createContext<Partial<GlobalSettings>>({})

export function useSettings() {
    const context = useContext(SettingsContext)

    return context
}
